<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="48" />
    </v-overlay>

    <confirm-dialog @confirm="confirmDelete" />

    <v-data-table
      :headers="headers"
      :items="formasPagamento"
      sort-by="descricao"
      class="border"
    >
      <template v-slot:[`item.is_convenio`]="{ item: { is_convenio } }">
        {{ is_convenio ? 'Sim' : 'Não' }}
      </template>
      <template v-slot:[`item.created_at`]="{ item: { created_at } }">
        {{ created_at | toLocaleDateTimeString }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item: { updated_at } }">
        {{ updated_at | toLocaleDateTimeString }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="canUpdate" class="mx-1" color="primary" @click="$emit('edit', item)"
          >mdi-pencil</v-icon
        >
        <v-icon
          v-if="canDelete"
          class="mx-1"
          color="error"
          @click="() => deleteItem(item.public_id)"
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toLocaleDateTimeString } from '@/mixins/convertion'

import ConfirmDialog from '@/components/template/ConfirmDialog'

import { BasicPermissions, Sequences } from "@/utils/permissions"

export default {
  name: 'FormaPagamentoList',
  props: {
    tela: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Convênio',
          value: 'is_convenio',
        },
        {
          text: 'Adicionado em',
          value: 'created_at',
        },
        {
          text: 'Adicionado por',
          value: 'created_by_name',
        },
        {
          text: 'Atualizado em',
          value: 'updated_at',
        },
        {
          text: 'Atualizado por',
          value: 'updated_by_name',
        },
        { text: 'Ações', value: 'actions' },
      ],
      loading: false,
    }
  },
  components: { ConfirmDialog },
  filters: { toLocaleDateTimeString },
  computed: {
    ...mapState('formaPagamento', ['formasPagamento']),

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, Sequences.FormaPagamento.toString())
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, Sequences.FormaPagamento.toString())
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, Sequences.FormaPagamento.toString())
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, Sequences.FormaPagamento.toString())
    },
  },
  methods: {
    ...mapActions('formaPagamento', [
      'getFormasPagamento',
      'deleteFormaPagamento',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    deleteItem(itemID) {
      this.itemID = itemID
      this.setDialogMessage({
        title: 'Confirmação de exclusão!',
        message: 'Deseja realmente excluir esta forma de pagamento?',
      })
    },
    async confirmDelete() {
      try {
        await this.deleteFormaPagamento(this.itemID)
        this.successMessage('Forma de Pagamento excluída com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
  async created() {
    if (this.canList) {
      this.loading = true
      await this.getFormasPagamento()
      this.loading = false
    }
  },
}
</script>
