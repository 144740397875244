<template>
  <validation-observer v-slot="{ invalid }">
    <v-card class="pa-2">
      <v-card-title> <slot name="title"></slot> </v-card-title>
      <v-card-text>
        <validation-provider
          name="descricao"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Forma de Pagamento"
            v-model.trim="form.descricao"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider name="is_convenio">
          <v-checkbox v-model="form.is_convenio" label="Convênio?" />
        </validation-provider>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <slot name="actions" :invalid="invalid" :form="form" />
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
export default {
  name: 'FormaPagamentoForm',
  props: {
    formProp: {
      type: Object,
      default: () => ({
        descricao: '',
        is_convenio: false,
      }),
    },
  },
  data() {
    return {
      form: {
        descricao: this.formProp.descricao,
        is_convenio: this.formProp.is_convenio,
      },
    }
  },
}
</script>
