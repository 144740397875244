<template>
  <v-dialog :value="value" max-width="500px" @click:outside="close">
    <forma-pagamento-form @close="close" @save="save">
      <template #title> Adicionar Forma de Pagamento </template>
      <template #actions="{ invalid, form }">
        <v-btn plain @click="close">Cancelar</v-btn>
        <v-btn color="error" :disabled="invalid" @click="save(form)"
          >Adicionar</v-btn
        >
      </template>
    </forma-pagamento-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import FormaPagamentoForm from '@/components/financeiro/forma-pagamento/FormaPagamentoForm'

export default {
  name: 'FormaPagamentoAdd',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  components: { FormaPagamentoForm },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('formaPagamento', ['addFormaPagamento']),

    close() {
      this.$emit('close')
    },
    async save(form) {
      try {
        await this.addFormaPagamento(form)
        this.successMessage('Forma de Pagamento adicionada com sucesso!')
        this.close()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
