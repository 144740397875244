<template>
  <v-dialog :value="value" max-width="500px" @click:outside="close">
    <forma-pagamento-form @close="close" @save="save" :formProp="item">
      <template #title> Editar Forma de Pagamento </template>
      <template #actions="{ invalid, form }">
        <v-btn plain @click="close">Cancelar</v-btn>
        <v-btn color="error" :disabled="invalid" @click="save(form)"
          >Salvar</v-btn
        >
      </template>
    </forma-pagamento-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import FormaPagamentoForm from '@/components/financeiro/forma-pagamento/FormaPagamentoForm'

export default {
  name: 'FormaPagamentoEdit',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: { FormaPagamentoForm },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('formaPagamento', ['editFormaPagamento']),

    close() {
      this.$emit('close')
    },
    async save(form) {
      try {
        await this.editFormaPagamento({
          ...form,
          public_id: this.item.public_id,
        })
        this.successMessage('Forma de Pagamento atualizada com sucesso!')
        this.close()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
