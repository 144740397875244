var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"48"}})],1),_c('confirm-dialog',{on:{"confirm":_vm.confirmDelete}}),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.formasPagamento,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"item.is_convenio",fn:function(ref){
var is_convenio = ref.item.is_convenio;
return [_vm._v(" "+_vm._s(is_convenio ? 'Sim' : 'Não')+" ")]}},{key:"item.created_at",fn:function(ref){
var created_at = ref.item.created_at;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var updated_at = ref.item.updated_at;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(updated_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"error"},on:{"click":function () { return _vm.deleteItem(item.public_id); }}},[_vm._v("mdi-delete")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }