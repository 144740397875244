<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Formas de Pagamento">
          <template #right>
            <v-btn v-if="canCreate" color="primary" @click="isCadastro = true">
              Adicionar
            </v-btn>
          </template>
          <forma-pagamento-list
            :tela="permission"
            @edit="edit"
            class="mt-6" />
          <forma-pagamento-add
            v-if="isCadastro"
            :value="isCadastro"
            @close="isCadastro = false"
          />
          <forma-pagamento-edit
            v-if="isEditing"
            :value="isEditing"
            :item="item"
            @close="isEditing = false"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormaPagamentoList from './FormaPagamentoList.vue'
import FormaPagamentoAdd from './FormaPagamentoAdd.vue'
import FormaPagamentoEdit from './FormaPagamentoEdit.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'FormaPagamento',
  components: { FormaPagamentoList, FormaPagamentoAdd, FormaPagamentoEdit },
  data() {
    return {
      Sequences,
      page: {
        title: 'Formas de Pagamento',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: false,
        },
        {
          text: 'Formas de Pagamento',
          disabled: true,
        },
      ],
      isEditing: false,
      isCadastro: false,
      item: {},
    }
  },
  computed: {
    permission() {
      return Sequences.FormaPagamento.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  methods: {
    edit(item) {
      this.isEditing = true
      this.item = item
    },
  },
}
</script>
